.startPage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 0.5px;
}

.startPage .secondStartCont {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--Black, #16205f);
  font-style: normal;
  margin: 30px 0 0 0;
}

.startPage .secondStartCont h1 {
  font-weight: 600;
  margin: 15px 0;
}

.startPage .secondStartCont p {
  width: 450px;
  margin: 10px 0;
  font-size: 1.1rem;
}

.startPage .secondStartCont .box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
}

.startPage .secondStartCont .box .inBox {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.startPage .secondStartCont .box .inBox img {
  width: 60px;
  height: 60px;
  padding: 5px;
  border-radius: 12px;
  background: linear-gradient(90deg, #30bcc9 10.63%, #313968 100%);
  margin-right: 15px;
}

.startPage .secondStartCont .box .inBox span {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.startPage .secondStartCont .box .inBox span p:nth-child(1) {
  width: auto;
  margin: 0 5px 5px 5px;
  font-weight: 600;
  font-size: 18px;
}

.startPage .secondStartCont .box .inBox span p:nth-child(2) {
  width: auto;
  margin: 0px;
  margin: 10px 5px 5px 5px;
  font-weight: 400;
  font-size: 15px;
}

.startPage .secondStartCont .box .show:nth-child(2) {
  transition-delay: 500ms;
}

.startPage .secondStartCont .box .show:nth-child(3) {
  transition-delay: 1s;
}

.startPage .thirdStartCont {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--Black, #16205f);
  font-style: normal;
  margin: 30px 0;
}

.startPage .thirdStartCont h2 {
  font-weight: 600;
  margin: 20px 0;
}

.startPage .thirdStartCont .box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.startPage .thirdStartCont .box h4 {
  width: calc(100% / 3);
  margin: 20px 0;
  font-weight: 600;
}

.developers-image {
  border-radius: 15px;
  color: rgb(255, 255, 255);
  position: relative;
  background-position: center;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 100px;
  transform-origin: background-image;
}

.developers-image .image-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  bottom: 30px;
  left: 20px;
  text-align: start;
}
/* hidden Animation */
.hidden {
  opacity: 0;
  filter: blur(1px);
  visibility: hidden;
  transition: all linear 1s; /* Delay visibility change */
}

/* Show Animation */
.show {
  opacity: 1;
  filter: blur(0);
  visibility: visible;
}


@media (max-width: 1000px) {
  .startPage .secondStartCont{
    align-items: flex-start;
  }

  .startPage .secondStartCont .box{
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;
  }

  .startPage .secondStartCont .box .inBox span p{
    align-self: start;
  }

  .startPage .secondStartCont .box .inBox{
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .startPage .thirdStartCont .box {
    flex-direction: column;
  }
  
  .startPage .thirdStartCont .box h4 {
    width: auto;
  }

  .startPage .thirdStartCont h3 {
    width: auto;
  }
  .startPage .thirdStartCont .box h6 {
    width: 100%;
    align-self: center;
  }

  .startPage .secondStartCont p{
    width: 90%;
  }
}

@media (max-width: 576px) {
  .developers-image .image-content h1 {
    text-align: start;
  }

  .developers-image .image-content p {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1361px) {
  .startPage .sizeBox {
    width: 1360px;
    display: flex;
    flex-direction: column;
  }
}