.chooseUsCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #070707, #1f041e);
  padding: 50px 0;
}

.chooseUsCont .sizeBox {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 1361px) {
  .chooseUsCont .sizeBox {
    width: 1360px;
  }
}

.chooseUsCont h1 {
  color: #fff;
  margin-top: 30px;
}

.chooseUsCont p {
  width: 700px;
  color: #fff;
  margin-top: 20px;
  text-align: center;
}

.chooseUsCont .contentBox {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 10px;
}

.chooseUsCont .contentBox .cBox {
  width: 25%;
  height: 230px;
  background-color: #141313;
  color: #fff;
  border: 0.5px solid rgb(41, 40, 40);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.chooseUsCont .contentBox .cBox:hover {
  background-color: #fff;
  color: #070707;
}

.chooseUsCont .contentBox .cBox img {
  width: 35px;
  padding: 8px;
  border-radius: 5px;
  margin: 10px 0;
}

.chooseUsCont .contentBox .cBox h3 {
  margin-top: 10px;
  text-align: center;
}

.chooseUsCont .contentBox .cBox p {
  width: fit-content;
  margin-top: 10px;
  text-align: center;
  font-weight: 100;
  letter-spacing: 1px;
  transition: all 0.2s linear;
}

.chooseUsCont .contentBox .cBox:hover p {
  color: #141313;
}

@media only screen and (max-width: 700px) {
  .chooseUsCont p {
    width: auto;
  }

  .chooseUsCont .contentBox {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
  }

  .chooseUsCont .contentBox .cBox {
    width: 90%;
    margin: 0;
  }
}
