.teams-slider-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to right, #191617, rgba(0, 18, 34, 0.938));
  position: relative;
}

.teams-slider-container .teamBox {
  width: 100%;
  padding: 20px 0;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.teams-slider-container .heading,
.teams-slider-container p {
  text-align: center;
  margin: 15px 8px;
}

.teams-slider-container .teamSlider {
  width: 80%;
}

.teams-slider-container .swiper_container {
  padding: 3rem 0;
  position: relative;
}

.teams-slider-container .swiper_container .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: transparent;
  border-style: none;
}

.teams-slider-container .swiper_container .swiper-slide .active {
  border-style: none;
}

.teams-slider-container .swiper_container .grid-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  border-style: none;
  padding: 10px 0;
}

.teams-slider-container .swiper_container .swiper-slide .teamIndividual {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 20px;
}

.teams-slider-container .swiper_container .swiper-slide .teamIndividual img {
  width: 170px;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.teams-slider-container .swiper_container .swiper-slide .teamIndividual .data {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.teams-slider-container
  .swiper_container
  .swiper-slide
  .teamIndividual
  .data
  span:nth-child(1) {
  font-size: 1.7rem;
  font-weight: 600;
}

.teams-slider-container
  .swiper_container
  .swiper-slide
  .teamIndividual
  .data
  span:nth-child(2) {
  font-size: 1.1rem;
  margin-top: 10px;
  font-weight: 550;
}

.teams-slider-container
  .swiper_container
  .swiper-slide
  .teamIndividual
  .data
  p {
  text-align: start;
  font-size: 1.05rem;
  margin: 0;
  margin-top: 10px;
  font-weight: 100;
}

.teams-slider-container
  .swiper_container
  .swiper_container
  .swiper-slide-shadow-left,
.teams-slider-container
  .swiper_container
  .swiper_container
  .swiper-slide-shadow-right {
  display: none;
}

.teams-slider-container .swiper_container .slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  opacity: 0;
  pointer-events: none;
  display: none !important;
}

.teams-slider-container .swiper_container .slider-controler .swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: -10px;
  display: flex;
  justify-content: center;
}

.teams-slider-container .swiper_container .swiper-pagination-bullet {
  display: none;
}

@media only screen and (min-width: 1460px) {
  .teams-slider-container .teamBox {
    width: 1460px;
  }
}

@media only screen and (max-width: 800px) {
  .teams-slider-container .teamSlider {
    width: 95%;
  }

  .teams-slider-container
    .swiper_container
    .swiper-slide
    .teamIndividual
    .data {
    width: 95%;
  }
}

@media only screen and (max-width: 652px) {
  .teams-slider-container .swiper_container .swiper-slide .teamIndividual {
    height: auto;
    flex-direction: column;
    margin: 20px 0;
  }

  .teams-slider-container .swiper_container .swiper-slide .teamIndividual img {
    height: 200px;
  }

  .teams-slider-container
    .swiper_container
    .swiper-slide
    .teamIndividual
    .data {
    align-items: center;
  }

  .teams-slider-container
    .swiper_container
    .swiper-slide
    .teamIndividual
    .data
    p {
    text-align: center;
  }
}
