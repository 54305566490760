@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-family: "Play", sans-serif;
  font-style: normal;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body a {
  text-decoration: none;
}

:root {
  --primary-color: #3a0f67;
  --secondary-color: #108ea8;

  --main-text-color: #16205f;
  --main-textSecond-color: #fff;
  --main-background-color: #fff;
  --main-backGradient-color: linear-gradient(
    90deg,
    #30bcc9 3.55%,
    #313968 100%
  );
  --main-second-backGradient-color: linear-gradient(
    90deg,
    #ed7901 0%,
    #fcb81f 50%
  );
  --main-card-back-color: rgba(25, 28, 128, 0.548);
  --main-cardbodybackGradient-color: linear-gradient(
    75deg,
    rgb(0, 183, 255),
    rgb(245, 54, 213),
    white,
    rgb(0, 60, 255),
    rgb(129, 20, 255)
  );
}

/* --------------- Webkit Scrollbar --------------- */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #1f1f1f;
  border: 6px solid transparent;
}
