.main-timeline {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  padding: 40px 0;
}

.main-timeline .sizeBox {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 1361px) {
  .main-timeline .sizeBox {
    width: 1360px;
  }
}

.main-timeline h1 {
  color: #112e42;
  margin-bottom: 10px;
}

.main-timeline .timeContent {
  width: 100%;
}

.main-timeline .timeContent .serviceTime {
  width: 100%;
  margin-bottom: 50px;
}

.main-timeline .container {
  box-sizing: border-box;
  padding: 2px 35px;
  width: 50%;
  animation: movedown 1s linear forwards;
  opacity: 0;
  cursor: pointer;
  position: relative;
  padding-top: 35px;
}

.main-timeline .left-container {
  top: 50px;
  left: 0;
}

.main-timeline .right-container {
  top: 50px;
  left: 49.7%;
}

.main-timeline .imgBox {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 40px;
  border-radius: 50%;
}

.main-timeline .left-img {
  right: -180px;
}

.main-timeline .right-img {
  left: -180px;
}

.main-timeline .imgBox img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px;
}

.main-timeline .rounded-box {
  position: absolute;
  top: 35px;
  z-index: 10;
  background: #ffffffee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-timeline .left-container .rounded-box {
  left: 98.5%;
}

.main-timeline .right-container .rounded-box {
  left: -1%;
}

.main-timeline .left-container .rounded-box .innerRound {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #e100ff;
}

.main-timeline .right-container .rounded-box .innerRound {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #0077ff;
}

.main-timeline .container .timelineBoxContent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.main-timeline .left-container .timelineBoxContent {
  align-items: flex-end;
}

.main-timeline .container .timelineBoxContent h2 {
  color: #037768;
}

.main-timeline .container .timelineBoxContent .techContBox {
  width: 80%;
  min-height: 100px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: all 0.1s ease-in;
}

.main-timeline .container .timelineBoxContent .techContBox:hover {
  background-color: #06221e;
}

.main-timeline .container .timelineBoxContent .techContBox p {
  color: #695801;
}

.main-timeline .container .timelineBoxContent .techContBox:hover p {
  color: #fff;
}

.main-timeline .container .timelineBoxContent .techContBox a {
  background: linear-gradient(to left, #5205a5, #0287a1);
  font-size: 0.9rem;
  width: fit-content;
  height: fit-content;
  margin-top: 15px;
  padding: 8px 15px;
  color: #fff;
}

.main-timeline .timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 100%;
  background: linear-gradient(to top, #7637b9, #108ea8);
  top: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
  animation: moveline 6s linear forwards;
}

@keyframes moveline {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

.main-timeline .timelinediv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .main-timeline .container .timelineBoxContent .techContBox {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .main-timeline .left-container .rounded-box {
    left: 97.5%;
  }

  .main-timeline .right-container .rounded-box {
    left: -2%;
  }
}

@media only screen and (max-width: 630px) {
  .main-timeline .left-container .rounded-box {
    left: 5px;
  }

  .main-timeline .right-container .rounded-box {
    left: 5px;
  }

  .main-timeline .imgBox {
    width: 80px;
    height: 80px;
    border: 2px solid #06221e;
  }

  .main-timeline .left-container .timelineBoxContent {
    align-items: flex-start;
  }

  .main-timeline .right-container {
    top: 50px;
    left: 0;
  }

  .main-timeline .left-img {
    left: auto;
    right: 4%;
    top: 60px;
  }

  .main-timeline .right-img {
    left: auto;
    right: 4%;
    top: 60px;
  }

  .main-timeline .container {
    width: 100%;
  }

  .main-timeline .container .timelineBoxContent .techContBox {
    margin-top: 30px;
    padding-top: 60px;
  }
}
