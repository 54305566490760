.modal-overlay-apply {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.705); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay-apply .modal-content {
  background-color: white;
  padding: 25px 20px;
  border-radius: 10px;
  max-width: 330px;
  width: 100%;
  text-align: center;
  animation: modalOpen 0.3s ease-out; /* Transition effect */
  transform: scale(1); /* Start small */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Animation from small to big */
@keyframes modalOpen {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-overlay-apply .modal-content span {
  font-size: 1.5rem;
}

.modal-overlay-apply .modal-content span b {
  background: linear-gradient(to left, #7637b9, #108ea8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-overlay-apply .modal-content form {
  width: 90%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.modal-overlay-apply .modal-content form .fbox {
  width: 90%;
  padding: 5px 8px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #108ea8;
}

.modal-overlay-apply .modal-content form .fbox input {
  width: 90%;
  padding: 8px;
  border-style: none;
  font-size: 0.95rem;
  color: #2b2b2b;
}

.modal-overlay-apply .modal-content form .fbox input:focus {
  outline: none;
}

.modal-overlay-apply .modal-content form .btnGrp {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.modal-overlay-apply .modal-content form .btnGrp button {
  border-style: none;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 0.95rem;
  border-radius: 5px;
  color: #fff;
}

@media only screen and (max-width: 450px) {
  .modal-overlay-apply .modal-content {
    margin: 0 20px;
  }

  .modal-overlay-apply .modal-content form .fbox {
    width: 95%;
  }
}
