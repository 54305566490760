.slider-main {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: auto;
  overflow: hidden;
}
.slider-main .background-color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  background: linear-gradient(to right, rgba(12, 12, 12, 0.959), #7637b934);
}

.slider-main .slide {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  animation: slide-out 0.8s forwards;
}

.slider-main .slide.active {
  display: flex;
  opacity: 1;
  animation: slide-in 0.8s forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100px);
    opacity: 0.5;
  }
}

.slider-main .slide-content {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: start;
  flex-direction: column;
  width: 50%;
  /* margin: 0 auto; */
  display: flex;
  /* align-items: start; */
  justify-content: start;
  background: rgba(0, 0, 0, 0);
  border-radius: 5px;
  color: #fff;
}
.slide-content h2 {
  font-size: 2.3rem;
  font-weight: 600;
}
.slider-main .slide-content p {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.1rem;
  letter-spacing: 1px;
  font-weight: 400;
}

.slider-main .slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.slider-main .arrow {
  position: absolute;
  bottom: 20px;
  height: 45px;
  width: 45px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  color: #fff;
  z-index: 10;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-main .left-arrow {
  left: 46%;
}

.slider-main .right-arrow {
  right: 46%;
}

.slider-main .arrow:hover {
  background: rgba(0, 0, 0, 0.7);
  scale: 1.15;
  transition: scale 0.5s ease;
}

.slider-main .arrow:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dotted #fff;
  border-radius: 50%;
  animation: rotatediv 0.5s linear infinite;
}

@keyframes rotatediv {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1200px) {
  .slider-main {
    height: 100vh;
  }
  .slider-main .slide img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .slider-main .left-arrow {
    left: 45%;
  }
  .slider-main .right-arrow {
    right: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .slider-main .left-arrow {
    left: 43%;
  }
  .slider-main .right-arrow {
    right: 43%;
  }
}
@media only screen and (max-width: 800px){
  .slider-main .slide-content{
    width: 75%;
    left: 42%;
  }

}
@media only screen and (max-width: 500px){
  .slider-main .slide-content{
    width: 85%;
    top: 50%;
    left: 48%;
    padding: 50px 0;
  }
}
@media only screen and (max-width: 700px) {
  .slider-main .left-arrow {
    left: 41%;
  }
  .slider-main .right-arrow {
    right: 41%;
  }
  .slider-main .slide-content p {
    /* padding: 0 4rem; */
  }
  
}
@media only screen and (max-width: 600px) {
  .slider-main .left-arrow {
    left: 40%;
  }
  .slider-main .right-arrow {
    right: 40%;
  }
}
@media only screen and (max-width: 500px) {
  .slider-main {
    height: 94vh;
  }
  .slider-main .slide img {
    width: 100%;
    height: 94vh;
    object-fit: cover;
  }
  .slider-main .left-arrow {
    left: 38%;
  }
  .slider-main .right-arrow {
    right: 38%;
  }
  .slider-main .slide-content p {
    /* padding: 0 2rem; */
  }
}
@media only screen and (max-width: 400px) {
  .slider-main .left-arrow {
    left: 35%;
  }
  .slider-main .right-arrow {
    right: 35%;
  }
  .slider-main .slide-content p {
    /* padding: 0 1rem; */
    font-size: 15.5px;
  }
}
@media only screen and (max-width: 350px) {
  .slider-main .left-arrow {
    left: 32%;
  }
  .slider-main .right-arrow {
    right: 32%;
  }
}
