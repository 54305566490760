.main-swiper-project {
  position: relative;
}
.main-swiper-project .shadow-left {
  position: absolute;
  top: 0;
  left: -20px;
  width: 300px;
  height: 100%;
  background: linear-gradient(
    to right,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  pointer-events: none;
  z-index: 2;
}
.main-swiper-project .shadow-right {
  position: absolute;
  top: 0;
  right: -20px;
  width: 300px;
  height: 100%;
  background: linear-gradient(
    to left,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  pointer-events: none;
  z-index: 2;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.project-slide {
  position: relative;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.project-text {
  position: absolute;
  text-align: left;
  padding: 20px;
  height: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(9, 1, 32, 0.644);
}
.project-text h3 {
  font-size: 19px;
  margin-bottom: 5px;
}
.project-text p {
  font-size: 15px;
}

.project-slide .icon-value {
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  padding: 8px 20px;
  color: rgb(31, 31, 31);
  margin-bottom: 8px;
}

:root {
  --swiper-navigation-size: 20px !important;
}

.main-swiper-project .img-project {
  height: 350px;
}

@media only screen and (max-width: 530px) {
  .main-swiper-project .shadow-left,
  .main-swiper-project .shadow-right,
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
