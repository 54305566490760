/* Updated Navbar CSS */
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
  /* padding: 20px 0; */
  position: fixed;
  top: 0;
  z-index: 999;
  margin: 0;
  transition: background 0.4s linear, height 0.1s linear;
}

.navbar .sizeBox {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 1361px) {
  .navbar .sizeBox {
    width: 1360px;
  }
}

.navbar .box img {
  width: 60px;
}

.navbar .box:nth-child(2) {
  width: 600px;
  background-color: #121212;
  border-radius: 25px;
  padding: 2px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.navbar .box:nth-child(2) a {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
}

.navbar .animation {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80%;
  margin: 0 5px;
  z-index: 0;
  background: linear-gradient(to left, #7637b9, #108ea8);
  border-radius: 25px;
  transition: all 0.5s ease 0s;
}

.navbar .box:nth-child(2) a:nth-child(1) {
  width: 40px;
}

.navbar .box:nth-child(2) a:nth-child(1).active ~ .animation {
  width: 80px;
  left: 0;
}

.navbar .box:nth-child(2) a:nth-child(2) {
  width: 103px;
}

.navbar .box:nth-child(2) a:nth-child(2).active ~ .animation {
  width: 130px;
  left: 88px;
}

.navbar .box:nth-child(2) a:nth-child(3) {
  width: 64px;
}

.navbar .box:nth-child(2) a:nth-child(3).active ~ .animation {
  width: 90px;
  left: 235px;
}

.navbar .box:nth-child(2) a:nth-child(4) {
  width: 104px;
}

.navbar .box:nth-child(2) a:nth-child(4).active ~ .animation {
  width: 130px;
  left: 345px;
}

.navbar .box:nth-child(2) a:nth-child(5) {
  width: 67px;
}

.navbar .box:nth-child(2) a:nth-child(5).active ~ .animation {
  width: 100px;
  left: 490px;
}

.navbar .box:nth-child(3) a {
  text-decoration: none;
  padding: 5px 20px;
  font-weight: 550;
  border-radius: 10px;
  transition: all 0.2s ease-in;
}

.navbar .box:nth-child(3) a:hover {
  background-color: #fff;
  color: #121212;
}

.navbar .box:nth-child(3) a.active {
  background-color: #fff;
  color: #121212;
}

.navbar .box:nth-child(4) {
  display: none;
}

@media only screen and (max-width: 889px) {
  .navbar .box:nth-child(2),
  .navbar .box:nth-child(3) {
    display: none;
  }

  .navbar .box:nth-child(4) {
    display: block;
  }
}

.navbar .responsiveNav {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  top: 80px;
}

@media only screen and (min-width: 889px) {
  .navbar .responsiveNav {
    display: none;
  }
}

.navbar .responsiveNav a {
  font-size: 0.6rem;
  text-transform: uppercase;
  color: #121212;
  text-decoration: none;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-weight: 600;
  transition: all 1s linear;
}

.navbar .responsiveNav a .size-6 {
  width: 25px;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 1s linear;
}

.navbar .responsiveNav a.active .size-6 {
  background: linear-gradient(to left, #7637b9, #108ea8);
  color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.navbar .responsiveNav a.active {
  color: #03738a;
}
