.main-timeline1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 880px;
}

.timeline1 {
  height: 0;
  position: absolute;
  width: 3px;
  background: linear-gradient(to top, #7637b9, #108ea8);
  top: 480px;
}

@media only screen and (max-width: 900px) {
  .main-timeline1 {
    height: 1000px;
  }
  .timeline1 {
    top: 530px;
  }
}

@media only screen and (max-width: 700px) {
  .main-timeline1 {
    height: 1100px;
  }
  .timeline1 {
    top: 580px;
  }
}

@media only screen and (max-width: 630px) {
  .main-timeline1 {
    height: 1100px;
    display: block !important;
  }
  .timeline1 {
    background: linear-gradient(to top, #7637b9, #108ea8);
    top: 570px;
    left: 5%;
  }
  .main-timeline .timelinediv {
    margin-left: 14px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media only screen and (max-width: 400px) {
  .main-timeline1 {
    height: 1200px;
    display: block !important;
  }
  .timeline1 {
    background: linear-gradient(to top, #7637b9, #108ea8);
    top: 620px;
    left: 5%;
  }
}
