.footerBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(90deg, rgb(5, 23, 39) 30%, rgb(8, 1, 27) 100%);
  color: #fff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.footerBox .secondContFootBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.footerBox .secondContFootBox .innerBox {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footerBox .secondContFootBox .footBox {
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.footerBox .secondContFootBox .footBox:nth-child(1) {
  margin-right: 30px;
}
.footerBox .secondContFootBox .footBox img {
  width: 60px;
  flex-shrink: 0;
  margin-bottom: 15px;
}

.footerBox .secondContFootBox .footBox p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.footerBox .secondContFootBox .footBox:not(:first-child) p:nth-child(1) {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0;
}

.footerBox .secondContFootBox .footBox .footLink,
.footerBox a {
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0;
  color: #fff;
}

@media only screen and (min-width: 1221px) {
  .footerBox .secondContFootBox .innerBox {
    width: 1220px;
  }
}

@media only screen and (max-width: 700px) {
  .footerBox .secondContFootBox .innerBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .footerBox .secondContFootBox .footBox {
    width: 90%;
    margin: 3px 0;
  }
}

.footerBox .thirdContFootBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 0.5px solid #c4c4c4;
  padding-bottom: 25px;
}

.footerBox .thirdContFootBox .innerBox {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.footerBox .thirdContFootBox .social {
  margin-top: 10px;
}

.footerBox .thirdContFootBox .social svg {
  margin-right: 20px;
}

.footerBox p {
  align-self: flex-start;
}

.footerBox .fourthContFootBox {
  width: 90%;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}

.footerBox .fourthContFootBox p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (min-width: 1221px) {
  .footerBox .thirdContFootBox .innerBox {
    width: 1220px;
  }

  .footerBox .fourthContFootBox {
    width: 1220px;
  }
}
