.Technology-main {
  padding: 0;
}
.Technology-main .tech-head {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)),
    url("./assets/TechnologyMain.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}
.Technology-main .tech-head .tech-overlay {
  background-color: #ffffff;
  color: #000;
  width: 65%;
  max-width: 800px;
  height: 240px;
  padding: 0 30px;
  margin: 0 auto;
  border-radius: 10px;
  opacity: 0.7;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 40px;
}
.Technology-main .tech-head .tech-overlay h1 {
  font-size: 4rem;
  font-weight: 800;
}
.Technology-main .tech-head .tech-overlay p {
  margin-top: 10px;
  padding: 0 12rem;
}

/* middle css */

.Technology-main .tech-middle {
  width: 100%;
  text-align: center;
  gap: 20px;
}
.Technology-main .tech-middle h2 {
  font-size: 2.2rem;
  /* padding: 10px 8rem; */
  width: 800px;

  margin: 15px auto;
}
.Technology-main .tech-middle .para-spec,
.Technology-main .tech-middle .para-tech {
  margin: 20px auto;
  width: 800px;
}

.Technology-main .tech-icons-all {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
}

.Technology-main .tech-icon {
  background-color: #3a0f67;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  width: 180px;
  height: 205px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  gap: 12px;
  position: relative;
  /* flex-basis: calc(100% / 4 - 60px); */
}
/* animations */
.Technology-main .tech-icon1:hover .lines {
  position: absolute;
  inset: -1px;
  background: #000;
  border-radius: 10px;
  overflow: hidden;
}
.Technology-main .tech-icon1:hover .lines::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 120px;
  border-radius: 20px;
  background: linear-gradient(
    transparent,
    #45f3ff,
    #45f3ff,
    #45f3ff,
    transparent
  );
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: animate 4s linear infinite;
  /* animation-play-state: paused; */
}
/* .Technology-main .tech-icon:hover .lines::before{
    animation-play-state: running;
  } */
.Technology-main .tech-icon1:hover .lines::after {
  content: "";
  position: absolute;
  inset: 3px;
  background: #292929;
  /* z-index: 10; */
  border-radius: 10px;
}
.Technology-main .tech-icon1:hover .tech-cut1,
.Technology-main .tech-icon1:hover .tech-cut2,
.Technology-main .tech-icon1:hover .tc1,
.Technology-main .tech-icon1:hover .tc2 {
  display: none;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.Technology-main .tech-icons-all .icon-img-tech {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Technology-main .tech-icon img {
  width: 60px;
  height: 62px;
  object-fit: contain;
  margin: 10px auto;
}

.Technology-main .tech-icon p {
  font-size: 25px;
  font-weight: 600;
}
.Technology-main .tech-icon:nth-child(-n + 3) {
  /* background-color: yellow; */
  position: relative;
  z-index: 1;
}
.Technology-main .tech-icon:nth-child(-n + 3) .tech-cut1 {
  position: absolute;
  height: 20px;
  width: 140px;
  clip-path: polygon(0 0, 100% 0%, 100% 99%, 40% 100%);
  border: none;
  background-color: #ffffff;
  z-index: 2;
  top: -1px;
  right: -1px;
}
.Technology-main .tech-icon:nth-child(-n + 3) .tech-corner1 {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  top: 18px;
  right: -1px;
  z-index: 3;
}
.Technology-main .tech-icon:nth-child(-n + 3) .tech-corner1::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #3a0f67;
  border-radius: 0 0 0 33px;
  top: 1px;
  left: -1px;
  transform: rotate(180deg);
}

.Technology-main .tech-icon:nth-child(n + 4) {
  position: relative;
  z-index: 1;
}
.Technology-main .tech-icon:nth-child(n + 4) .tech-cut2 {
  position: absolute;
  height: 20px;
  width: 140px;
  clip-path: polygon(0 0, 55% 0, 100% 99%, 0 99%);
  /* border: 1px solid red; */
  border: none;
  background-color: #ffffff;
  z-index: 2;
  bottom: -1px;
  left: -1px;
}
.Technology-main .tech-icon:nth-child(n + 4) .tech-corner2 {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  bottom: 17.8px;
  left: -0.2px;
  z-index: 3;
}
.Technology-main .tech-icon:nth-child(n + 4) .tech-corner2::before {
  content: "";
  position: absolute;
  width: 41px;
  height: 40px;
  background-color: #3a0f67;
  border-radius: 0 0 0 33px;
  top: -1.175px;
  left: 1px;

  /* transform: rotate(180deg); */
}

.Technology-main .tech-icons-all2 {
  background: linear-gradient(to bottom, #070707, #0e010d);
}
.Technology-main .tech-icons-all2 .main-tech2 {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  gap: 30px;
  justify-content: center;
  max-width: 940px;
  width: 100%;
  margin: 30px auto;
  align-items: center;
}
.Technology-main .tech-icons-all2 a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Technology-main .tech-para2 {
  padding: 0px 8px;
}
.Technology-main .tech-icon1:hover .tech-para2 {
  position: absolute;
  top: 60px;
  z-index: 40;
  color: #fff !important;
}

.Technology-main .tech-icon:nth-child(n + 4) .tc2::before {
  background-color: #ffffff;
}
.Technology-main .tech-icon:nth-child(-n + 3) .tc1::before {
  background-color: #ffffff;
}
/* .Technology-main .tech-icons-all2 .tech-icon h3 {
    font-size: 23px;
    font-weight: 600;
  } */

@media screen and (max-width: 1000px) {
  .Technology-main .tech-head {
    background-size: cover;
    margin-bottom: 20px;
  }
  .Technology-main .tech-middle .para-spec,
  .Technology-main .tech-middle .para-tech {
    font-size: 1rem;
    margin: 25px 0;
  }
}
@media screen and (max-width: 750px) {
  .Technology-main .tech-head .tech-overlay p {
    padding: 0 4rem;
  }
  .Technology-main .tech-middle {
    /* padding: 10px 4rem; */
    text-align: center;
    gap: 20px;
  }
}
@media screen and (max-width: 600px) {
  .Technology-main .tech-head .tech-overlay {
    width: 70%;
    height: 440px;
  }
  .Technology-main .tech-head .tech-overlay p {
    padding: 0 2rem;
  }
  .Technology-main .tech-head .tech-overlay h1 {
    font-size: 3.2rem;
  }
  .Technology-main .tech-middle {
    /* padding: 10px 3rem; */
  }
}
@media screen and (max-width: 600px) {
  .Technology-main .para-tech {
    display: none;
  }
  .Technology-main .tech-middle h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  /* hello */
  .Technology-main .tech-icons-all2 {
    padding: 40px 0;
  }
}

@media screen and (max-width: 385px) {
  .Technology-main .tech-head {
    height: 75vh;
  }
  .Technology-main .tech-head .tech-overlay h1 {
    font-size: 3rem;
  }
  .Technology-main .tech-middle {
    padding: 10px 0rem;
  }
  .Technology-main .tech-middle h2 {
    font-size: 1.7rem;
    /* margin-bottom: 30px; */
    margin: 10px 15px;
  }
  .Technology-main .tech-middle .para-spec {
    margin: 15px 15px;
  }
  .Technology-main .tech-icons-all2 {
    padding: 40px 0px;
  }
}
@media screen and (max-width: 350px) {
  .Technology-main .tech-head .tech-overlay h1 {
    font-size: 2.5rem;
  }
  .Technology-main .tech-head .tech-overlay p {
    padding: 0px 0rem;
    margin-top: 20px;
  }
  .Technology-main .tech-middle {
    padding: 10px 0rem;
    text-align: center;
    gap: 20px;
  }
}

/* for bigger screen */
@media screen and (min-width: 1500px) {
  .Technology-main .tech-middle {
    /* max-width: 1250px; */
    margin: 0 auto;
  }
  .Technology-main .tech-head {
    width: 100%;
    background-size: cover;
  }
}

/* animation  */

.Technology-main .tech-icon1:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.Technology-main .tech-icon1:hover .icon-img-tech img {
  transition: transform 0.4s ease;
}
.Technology-main .tech-icon1:hover .tech-para2 {
  transform: translateY(15px);
  transition: transform 0.4s ease;
}

.Technology-main .tech-icon1:hover .icon-img-tech1 {
  position: absolute;
  left: 50%;
  top: 20px;
  width: 110px;
  height: 110px;
  background: #000;
  transform: translateX(-50%);
  transition: 0.5s;
  z-index: 10;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
}
.Technology-main .tech-icon1:hover .icon-img-tech1::before {
  content: "";
  position: absolute;
  top: 50%;
  /* z-index: 100; */
  left: 50%;
  width: 200px;
  height: 70px;
  transform: translate(-50%, -50%);
  /* background: linear-gradient(transparent, #ff3c7b,#ff3c7b,#ff3c7b,transparent); */
  /* background: linear-gradient(transparent, #c97ae3,#cb73e9,#be67db,transparent); */
  background: linear-gradient(
    transparent,
    #bb2cd9,
    #bb2cd9,
    #bb2cd9,
    transparent
  );
  /* background: linear-gradient(transparent,#45f3ff,#45f3ff,#45f3ff,transparent); */
  animation: animate2 4s linear infinite;
}
/* #BB2CD9 */
.Technology-main .tech-icon1:hover .icon-img-tech1::after {
  content: "";
  position: absolute;
  inset: 2px;
  background: #292929;
  border-radius: 10px;
}
.Technology-main .tech-icon1:hover .icon-img-tech1 img {
  position: absolute;
  z-index: 20;
}

@keyframes animate2 {
  0% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

/* Blur background */
.Technology-main .tech-icons-all2 {
  transition: filter 0.3s ease;
}

/* .Technology-main .tech-icon.blur {
  filter: blur(2px);
} */

.Technology-main .tech-icon1 .content {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  height: 205px;
}
.Technology-main .tech-icon1 .content .details {
  padding: 0px 10px;
  text-align: center;
  width: 100%;
  transition: 0.5s;
  transform: translateY(50px);
}
.Technology-main .tech-icon1:hover .content .details {
  transform: translateY(0px);
}
.Technology-main .tech-icon1 .content .details h3 {
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.2em;
}
.Technology-main .tech-icon1:hover .content .details h3 {
  color: #45f3ff !important;
}
.Technology-main .tech-icon1 .content .details .techlast-para {
  margin-top: 25px;
  font-size: 12px !important;
  font-weight: 300 !important;
  color: #fff;
  padding: 0 5px;
  opacity: 0;
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  -webkit-line-clamp: 3;
}
.Technology-main .tech-icon1:hover .content .details .btn {
  display: inline-block;
  padding: 5px 14px;
  background: #fff;
  color: #000;
  margin: 6px;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  transition: 0.5s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.Technology-main .tech-icon1:hover .content .details .btn:hover {
  scale: 1.05;
  cursor: pointer;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.Technology-main .tech-icon1:hover .content .details .techlast-para {
  opacity: 1;
}

.Technology-main .tech-icons-all2 .tech-icon1:last-child .content {
  height: 220px;
}
.Technology-main .tech-icons-all2 .tech-icon1:hover:nth-child(4) .tech-para2 {
  font-size: 15px;
  transform: translateY(10px);
}
.Technology-main .tech-icons-all2 .tech-icon1:hover:nth-child(6) .tech-para2 {
  font-size: 11px;
  transform: translateY(13px);
}

.Technology-main .tech-icon1:hover .curve-tech {
  position: absolute;
  top: 12px;
  left: 55px;
  height: 30px;
  width: 86px;
  background-color: #000;
  /* inset: 1px; */
  clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  z-index: 10;
  border-top-left-radius: 10px;
}
.Technology-main .tech-icon1:hover .curve-tech::after {
  content: "";
  position: absolute;
  inset: 3px 3px 0px 3px;
  border-top-left-radius: 10px;
  background-color: #292929;
}

/* for first card animation  */

.Technology-main .tech-anime:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.Technology-main .tech-anime:hover .icon-img-tech2 {
  position: absolute;
  left: 50%;
  top: 20px;
  width: 110px;
  height: 110px;
  background: #000;
  transform: translateX(-50%);
  transition: 0.5s;
  z-index: 10;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
}
.Technology-main .tech-anime:hover .icon-img-tech2::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 70px;
  transform: translate(-50%, -50%);
  /* background: linear-gradient(transparent, #ff3c7b,#ff3c7b,#ff3c7b,transparent); */
  /* background: linear-gradient(transparent, #8d5ac0,#7536b4,#743aaf,transparent); */
  background: linear-gradient(
    transparent,
    #7e3896,
    #7e3896,
    #7e3896,
    transparent
  );
  animation: animate2 4s linear infinite;
}
.Technology-main .tech-anime:hover .icon-img-tech2::after {
  content: "";
  position: absolute;
  inset: 2px;
  background: #292929;
  border-radius: 50%;
}

.Technology-main .tech-anime:hover .lines {
  position: absolute;
  inset: -1px;
  background: #000;
  overflow: hidden;
  border-radius: 10px;
}
.Technology-main .tech-anime:hover .lines::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 120px;
  border-radius: 20px;
  background: linear-gradient(
    transparent,
    #45f3ff,
    #45f3ff,
    #45f3ff,
    transparent
  );
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: animate 4s linear infinite;
}

.Technology-main .tech-anime:hover .lines::after {
  content: "";
  position: absolute;
  inset: 3px;
  background: #292929;
  border-radius: 10px;
}
.Technology-main .tech-anime:hover .tech-cut1,
.Technology-main .tech-anime:hover .tech-cut2,
.Technology-main .tech-anime:hover .tc3,
.Technology-main .tech-anime:hover .tc4 {
  display: none;
}

.Technology-main .tech-anime:hover .above-head {
  position: absolute;
  top: 66px;
  z-index: 40;
  /* color: #fff !important; */
}
.Technology-main .tech-anime:hover .above-head {
  transform: translateY(40px);
  transition: transform 0.4s ease;
  font-size: 18px !important;
  width: 90px;
}
.Technology-main .tech-anime:hover .icon-img-tech2 img {
  position: absolute;
  z-index: 20;
}

/* para  */
.Technology-main .tech-anime .content {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  height: 205px;
}
.Technology-main .tech-icon:hover {
  cursor: pointer;
}
.Technology-main .tech-anime .content .details {
  padding: 0px 10px;
  text-align: center;
  width: 100%;
  transition: 0.5s;
  transform: translateY(50px);
}
.Technology-main .tech-anime:hover .content .details {
  transform: translateY(0px);
}
.Technology-main .tech-anime .content .details .above-head {
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.2em;
}
.Technology-main .tech-anime:hover .above-head {
  z-index: 50;
  color: #45f3ff !important;
}
/* .Technology-main .tech-anime:hover .above-head{
    color: white;
    z-index: 30;
  } */
.Technology-main .tech-anime .content .details .techlast-para {
  margin-top: 25px;
  font-size: 12px !important;
  font-weight: 300 !important;
  color: #fff;
  padding: 0 5px;
  opacity: 0;
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  -webkit-line-clamp: 3;
}
.Technology-main .tech-anime:hover .content .details .btn {
  display: inline-block;
  padding: 5px 14px;
  background: #fff;
  color: #000;
  margin: 6px;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  transition: 0.5s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.Technology-main .tech-anime:hover .content .details .btn:hover {
  scale: 1.05;
  cursor: pointer;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.Technology-main .tech-anime:hover .content .details .techlast-para {
  opacity: 1;
}

.Technology-main .tech-icons-all2 .tech-anime:last-child .content {
  height: 220px;
}
/* .Technology-main .tech-icons-all2 .tech-anime:hover:nth-child(4) .tech-para2{
    font-size: 15px;
    transform: translateY(10px);
  }
  .Technology-main .tech-icons-all2 .tech-anime:hover:nth-child(6) .tech-para2{
    font-size: 11px;
    transform: translateY(13px);
  } */

.Technology-main .tech-anime:hover .curve-tech {
  position: absolute;
  top: 12px;
  left: 55px;
  height: 30px;
  width: 86px;
  background-color: #000;
  /* inset: 1px; */
  clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  z-index: 10;
  border-top-left-radius: 10px;
}
.Technology-main .tech-anime:hover .curve-tech::after {
  content: "";
  position: absolute;
  inset: 3px 3px 0px 3px;
  border-top-left-radius: 10px;
  background-color: #292929;
}
.Technology-main .tech-anime:nth-child(5) img {
  width: 60px;
  height: 67px;
  object-fit: contain;
  margin: 10px auto;
}
.Technology-main .tech-anime:hover:nth-child(2) img {
  width: 70px !important;
  height: 70px !important;
}
.Technology-main .tech-anime:nth-child(5) .above-head {
  margin-bottom: 20px !important;
  margin-top: 0 !important;
  font-size: 22px;
}
.Technology-main .tech-anime:hover:nth-child(5) .above-head {
  font-size: 13px !important;
  top: 75px !important;
}
.Technology-main .tech-anime:hover .icon-img-tech2 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Technology-main .tech-anime:hover .icon-img-tech2 img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

@media only screen and (max-width: 800px) {
  .Technology-main .tech-middle h2 {
    font-size: 2rem;
    width: 90%;
    margin: 15px auto;
  }
  .Technology-main .tech-middle .para-spec,
  .Technology-main .tech-middle .para-tech {
    margin: 20px auto;
    width: 90%;
  }
}
