.client-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.above-head {
  overflow: hidden;
}

.above-head .text-center {
  text-align: center;
}

.above-head .marquee-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  margin: 20px 0;
}

/* Client Logos */
.above-head .client-logo {
  height: 3rem;
  width: auto;
  margin: 0 1rem;
}

.above-head .marquee-wrapper::before {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.1)
  );
}

.above-head .marquee-wrapper::after {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.1)
  );
}
