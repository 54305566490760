/* Marquee Container */
.marquee-container {
  display: flex;
  overflow: hidden;
  padding: 0.5rem;
  gap: 1rem;
  position: relative;
}

.marquee-container.horizontal {
  flex-direction: row;
}

.marquee-container.vertical {
  flex-direction: column;
}

/* Marquee Content */
.marquee-content {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  flex-shrink: 0;
}
/* for horizontal direction */
.marquee-content.animate-horizontal {
  animation: marquee-horizontal 45s linear infinite;
}

/* form vertical alignment */
.marquee-content.animate-vertical {
  animation: marquee-vertical 30s linear infinite;
}

/* Pause on Hover applied to the container */
.marquee-container.pause-on-hover:hover .marquee-content {
  animation-play-state: paused;
}

/* Reverse Direction */
.marquee-content.reverse {
  animation-direction: reverse;
}

/* Animations */
@keyframes marquee-horizontal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes marquee-horizontal2 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-vertical {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
