.blur-effect-left {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  left: 0;
  z-index: 2;
  height: 180px;
  pointer-events: none;
}
.blur-effect-right {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 180px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  z-index: 2;
}
.our-project-home {
  padding: 40px;
  background: #000;
}

.our-project-home .our-head {
  font-size: 38px;
  color: #fff;
  margin: 15px 0;
  font-weight: 600;
  text-align: center;
}
.our-project-home .our-para {
  font-size: 16px;
  color: #fff;
  margin-bottom: 50px;
  text-align: center;
  padding: 0 20rem;
}

@media only screen and (max-width: 1100px) {
  .our-project-home .our-head {
    font-size: 33px;
  }

  .our-project-home .our-para {
    font-size: 16px;
    padding: 0 10rem;
  }
}
@media only screen and (max-width: 768px) {
  .our-project-home .our-head {
    font-size: 33px;
  }

  .our-project-home .our-para {
    font-size: 16px;
    padding: 0 5rem;
  }
}
@media only screen and (max-width: 400px) {
  .our-project-home .our-head {
    font-size: 32px;
  }

  .our-project-home .our-para {
    font-size: 15px;
    padding: 0 0rem;
  }
}

.home-container .joinUs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-container .joinUs .companyName {
  background: linear-gradient(to left, #7637b9, #108ea8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
}

.home-container .joinUs .service-last {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
  color: #fff;
  padding-bottom: 20px;
}

.home-container .joinUs .service-last h1 {
  text-align: center;
  font-size: 2rem;
  margin: 20px 0;
  margin-bottom: 30px;
}

.home-container .joinUs .service-last .btn-one {
  border-radius: 20px;
  width: 200px;
  height: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  cursor: pointer;
  margin: 20px 0;
}

.home-container a {
  width: 150px;
  height: 40px;
  border-style: none;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: 550;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
