.heroHomeSlider {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(to right, rgba(12, 12, 12, 0.959), #7637b934);
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroHomeSlider .sizeBox {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 120px;
}

@media only screen and (min-width: 1361px) {
  .heroHomeSlider .sizeBox {
    width: 1360px;
  }
}

.heroHomeSlider .tag {
  width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

@media only screen and (max-width: 647px) {
  .heroHomeSlider .tag {
    width: 100%;
  }
}

.heroHomeSlider .tag h1 {
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.heroHomeSlider .tag .btn {
  margin-top: 50px;
  border-radius: 20px;
  width: 200px;
  height: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  position: relative;
  cursor: pointer;
  align-self: flex-start;
}

.heroHomeSlider .tag button {
  width: 150px;
  height: 40px;
  border-style: none;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: 550;
  background-color: #fff;
  cursor: pointer;
}

.heroHomeSlider .tag button a {
  text-decoration: none;
  color: #000;
}

.heroHomeSlider .hero-dot-play {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 120px;
  z-index: 1;
}

.heroHomeSlider .hero-dots {
  display: flex;
  align-items: center;
  gap: 15px;
  list-style: none;
}

.heroHomeSlider .hero-dots .hero-dot {
  width: 11px;
  height: 11px;
  border: 2px dotted #fff;
  border-radius: 50%;
  cursor: pointer;
  animation: loader 500ms linear infinite;
}

.heroHomeSlider .hero-dots .hero-dot.orange {
  background-color: #fff;
}

.heroHomeSlider .hero-play {
  display: flex;
  align-items: center;
  gap: 30px;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.heroHomeSlider .loader {
  cursor: pointer;
  position: relative;
  width: 28px;
  height: 28px;
  background-color: rgba(0, 0, 0, 0.479);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.heroHomeSlider .loader::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dotted #fff;
  border-radius: 50%;
  animation: loader 500ms linear infinite;
}

.heroHomeSlider .size-6 {
  color: #fff;
  z-index: 1;
}

.heroHomeSlider .scroll {
  width: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 10px 0;
  border: 1px solid #fff;
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
}

@keyframes scdown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(40px);
    opacity: 0;
  }
}

.heroHomeSlider .scroll .size-6 {
  color: #fff;
  width: 25px;
  font-weight: bold;
  animation: scdown 1.5s linear infinite;
}
