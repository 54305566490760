.conatctContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.conatctContainer .first_sec {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right, rgba(12, 12, 12, 0.89), #45197450),
    url("./Assets/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conatctContainer .first_sec .sizeBox {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conatctContainer .first_sec .thought {
  width: 35%;
  text-align: left;
  padding: 100px 20px;
}

.conatctContainer .first_sec .thought h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  color: #fff;
}

.conatctContainer .first_sec .thought span {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #fff;
}

.conatctContainer .first_sec .thought p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #fff;
  padding-top: 10px;
}

.conatctContainer .first_sec form {
  width: 35%;
  padding: 30px;
  border-radius: 5px;
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.24);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.conatctContainer .first_sec form .form-title {
  padding-bottom: 60px;
}

.conatctContainer .first_sec form .form-title h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: #212121;
}

.conatctContainer .first_sec form .input-row {
  display: flex;
  flex-direction: column;
}

.conatctContainer .first_sec form .input-row .input-box {
  position: relative;
  margin-bottom: 70px;
}

.conatctContainer .first_sec form .input-row .input-box input {
  position: absolute;
  width: 93%;
  padding: 15px;
  font-size: 15px;
  color: #666;
  border: none;
  border-radius: 3px;
  border: 1px solid #ececec;
  transition: ease 0.1s;
  z-index: 3;
}

.conatctContainer .first_sec form .input-row .input-box input:focus {
  color: #0f001f;
  font-size: 12px;
  outline: none;
}

.conatctContainer .first_sec form .submit .submit-btn {
  display: inline-block;
  width: 100%;
  height: 40px;
  background-color: #101828;
  color: #fff;
  border: 2px solid transparent;
  border-radius: 3px;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.conatctContainer .first_sec form .submit .submit-btn:hover {
  width: 96%;
  transform: translate(10px, 0);
  background: transparent;
  color: #101828;
  border-color: #101828;
}

.conatctContainer .secondCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.conatctContainer .secondCont .sizeBox {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.conatctContainer .secondCont .second_sec .title {
  width: 100%;
  font-size: 26px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
}

.conatctContainer .secondCont .second_sec .card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  padding-top: 50px;
}

.conatctContainer .secondCont .second_sec .card-row .card {
  width: 100%;
  padding: 30px;
  transition: ease-in-out 0.3s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.conatctContainer .secondCont .second_sec .card-row .card h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.02em;
  padding-bottom: 10px;
  border-bottom: 1px solid #ececec;
}

.conatctContainer .secondCont .second_sec .card-row .card .mail,
.conatctContainer .secondCont .second_sec .card-row .card .location {
  display: flex;
  justify-content: start;
  gap: 10px;
  padding: 15px 0;
}

.conatctContainer .secondCont .second_sec .card-row .card .call {
  display: flex;
  gap: 10px;
}

.conatctContainer .secondCont .second_sec .card-row .card .call a,
.conatctContainer .secondCont .second_sec .card-row .card .mail a,
.conatctContainer .secondCont .second_sec .card-row .card .location a {
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0;
  color: #101828;
}

.conatctContainer .secondCont .second_sec .card-row .card .call span {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.02em;
}

.conatctContainer .secondCont .second_sec .card-row .card:hover {
  background-color: #101828;
  color: #fff;
  transform: scale(1.02);
}

.conatctContainer .secondCont .second_sec .card-row .card:hover a {
  color: #fff;
}

/* -----------------------------Responsive--------------------------------- */
@media (max-width: 800px) {
  .conatctContainer .first_sec .sizeBox {
    justify-content: center;
    flex-direction: column;
  }

  .conatctContainer .first_sec .thought {
    width: 90%;
    padding-bottom: 20px;
  }

  .conatctContainer .first_sec form {
    width: 80%;
    margin: 20px 0;
  }
}

@media (min-width: 768px) {
  .conatctContainer .secondCont .second_sec .card-row > * {
    flex: 1;
  }
}

@media only screen and (min-width: 1361px) {
  .conatctContainer .first_sec .sizeBox {
    width: 1360px;
  }
}

.conatctContainer .secondCont .map {
  width: 100%;
  margin-top: 40px;
}
