.service-main {
  width: 100%;
  padding: 0;
}
.service-main .our-service {
  width: 100%;
  background-image: url("./assets/ServiceMain.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}

.service-main .our-service .overlay {
  background-color: #ffffff;
  color: #000;
  width: 65%;
  max-width: 800px;
  height: 240px;
  padding: 0 30px;
  margin: 0 auto;
  border-radius: 10px;
  opacity: 0.7;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 40px;
}

.service-main .our-service .overlay h1 {
  margin: 0;
  font-size: 4rem;
  font-weight: 800;
}

.service-main .our-service .overlay p {
  margin: 0;
  font-size: 1.1em;
  font-weight: 500;
  padding: 0 8rem;
  margin-top: 10px;
}

/* middle css */

.service-main .service-middle {
  padding: 10px 0;
  text-align: center;
}
.service-main .service-middle h2 {
  font-size: 2.2rem;
  margin-bottom: 10px;
  margin-top: 20px;
}
.service-main .service-middle p {
  font-size: 1.3rem;
}

.service-main .services-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1100px;
  margin: 30px auto;
}

.service-main .service-item {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

/* .service-main .service-item.active {
    background-color: #000;
    color: #fff;
  } */

.service-main .service-item h2 {
  grid-column: span 1 / span 1;
  font-size: 1.7em;
  margin: 0;
}
.service-main .service-item .service-des {
  grid-column: span 3 / span 3;
  text-align: left;
  padding: 0 8rem;
}
.service-main .service-item p {
  font-size: 1em;
  flex: 1;
}

.service-main .service-item .icon {
  grid-column: span 1 / span 1;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  margin: 0 auto;
  padding: 6px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-main .service-item:hover {
  transform: scale(1.07);
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.service-main .service-item:hover .icon {
  color: gray;
  background-color: #ffffff;
}

/* last section  */
.service-main .team-developers-container {
  text-align: center;
  padding: 40px;
  position: relative;
}

.service-main .header {
  background-color: #3a0f67;
  color: white;
  padding: 40px 20px;
  border-radius: 20px;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: space-between;
  gap: 10rem;
  height: 300px;
}
.service-main .service-cut1 {
  position: absolute;
  height: 30px;
  width: 550px;
  clip-path: polygon(100% 0, 85% 100%, 0 100%, 0 0);
  /* border: 1px solid red; */
  border: none;
  background-color: #ffffff;
  z-index: 1;
  top: -2px;
  left: 0;
}
.service-main .service-cut2 {
  position: absolute;
  height: 31px;
  width: 630px;
  clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
  border: none;
  background-color: #ffffff;
  z-index: 1;
  bottom: -1px;
  right: 0;
}
/* curve corner   */
.service-main .service-corner1 {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  top: 24px;
  left: 0;
}
.service-main .service-corner1::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #3a0f67;
  border-radius: 0 0 33px 0;
  top: 3px;
  left: 0;
  transform: rotate(180deg);
}
.service-main .service-corner2 {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  bottom: 24px;
  right: 0;
}
.service-main .service-corner2::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #3a0f67;
  border-radius: 0 0 33px 0;
  bottom: 6px;
  right: 0;
}

.service-main .header h1 {
  margin: 0;
  font-size: 2em;
  margin-top: 30px;
}

.service-main .header p {
  margin-left: 10px;
  font-size: 1em;
  margin-top: 30px;
}

.service-main .services-data {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  top: 17rem;
  text-align: center;
  z-index: 2;
}

.service-main .service-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 220px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
}
.service-main .service-card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 100%;
  background-color: #000;
  border-radius: 10px;
  z-index: -1;
  transition: top 0.3s linear;
  overflow: hidden;
}
.service-main .service-card:hover::before {
  top: 0;
}

.service-main .service-card:hover {
  transform: scale(1);
  color: #fff !important;
}

.service-main .service-card .icon-img {
  font-size: 2.5em;
  color: #4b0082;
  margin-bottom: 10px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-main .service-card h2 {
  font-size: 1.5em;
  margin: 10px 0;
}

.service-main .service-card p {
  font-size: 1em;
}

/* last css */

.service-main .service-last {
  margin: 9rem 0 0 0;
  text-align: center;
  background-color: #000;
  height: 300px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}
.service-main .service-last .service-lhead h2 {
  font-size: 2.5em;
  padding: 2rem 18rem;
}
.service-main .service-unique {
  display: flex;
  width: 300px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  /* position: relative; */
}


.service-main .service-unique .service-join {
  height: 55px;
  width: 180px;
  color: #000 !important;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-main .service-unique .service-join p {
  margin-top: 15px;
  font-size: 20px;
}
.service-main .service-cross {
  width: 40px;
  height: 10px;
  background-color: #ffffff;
  position: absolute;
  left: 53%;
}
.service-main .service-arrow {
  position: absolute;
  left: 2%;
  margin-top: 7px;
  z-index: 3;
}
.service-main .service-arrow img {
  width: 37px;
}
.service-main .service-circle {
  width: 49px;
  height: 55px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-left: 10px;
  position: relative;
}

@media screen and (max-width: 1240px) {
  .service-main .services-data {
    width: 95%;
  }
}
@media screen and (max-width: 1240px) {
  .service-main .service-last {
    margin: 30rem 0 0 0;
  }
}
@media screen and (max-width: 1000px) {
  .service-main .our-service {
    background-size: cover;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 950px) {
  .service-main .service-cross {
    left: 55%;
  }
  .service-main .service-last .service-lhead h2 {
    font-size: 2.5em;
    padding: 2rem 10rem;
  }
  .service-main .service-middle {
    padding: 10px 20px;
  }
}
@media screen and (max-width: 750px) {
  .service-main .service-middle {
    padding: 10px 30px;
  }
  .service-main .service-item .service-des {
    padding: 0px 5rem;
  }
  .service-main .our-service .overlay p {
    padding: 0 4rem;
  }
  .service-main .service-last .service-lhead h2 {
    font-size: 2.5em;
    padding: 2rem 5rem;
  }
  .service-main .service-cut1 {
    width: 380px;
  }
  .service-main .service-cut2 {
    width: 380px;
  }
  .service-main .header {
    gap: 3rem;
  }
  .service-main .service-corner1 {
    width: 38px;
  }
  .service-main .service-corner2 {
    width: 38px;
  }
}

@media screen and (max-width: 620px) {
  .service-main .services-data {
    width: 90%;
  }
}
@media screen and (max-width: 610px) {
  .service-main .service-last {
    margin: 72rem 0 0 0;
  }
  .service-main .header {
    gap: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .service-main .service-item h2 {
    font-size: 1.2rem;
  }

  .service-main .our-service .overlay {
    width: 70%;
    height: 440px;
  }
  .service-main .our-service .overlay p {
    padding: 0px 2rem;
  }
  .service-main .service-item p {
    display: none;
  }

  .service-main .header h1 {
    font-size: 1.6em;
  }
  .service-main .team-developers-container {
    padding: 25px;
  }
  .service-main .header {
    gap: 1rem;
  }
  .service-main .service-cross {
    left: 60%;
  }
}
@media screen and (max-width: 450px) {
  .service-main .our-service .overlay p {
    padding: 0px 1rem;
    margin-top: 20px;
  }
  .service-main .service-last .service-lhead h2 {
    font-size: 2em;
    padding: 2rem 1rem;
  }
  .service-main .service-cut1 {
    width: 250px;
  }
  .service-main .service-cut2 {
    width: 250px;
  }
  .service-main .team-developers-container {
    padding: 20px;
  }
  .service-main .header {
    padding: 40px 10px;
  }
  .service-main .service-last {
    margin: 74rem 0 0 0;
  }
}
@media screen and (max-width: 385px) {
  .service-main .team-developers-container {
    padding: 10px;
  }
  .service-main .our-service {
    height: 75vh;
  }
  .service-main .service-cut1 {
    width: 180px;
  }
  .service-main .service-cut2 {
    width: 180px;
  }
  .service-main .services-data {
    width: 95%;
  }
  .service-main .service-card {
    padding: 20px;
    width: 235px;
  }
  .service-main .service-last .service-lhead h2 {
    font-size: 2em;
    padding: 3rem 1rem;
  }
  .service-main .header {
    flex-direction: column;
    justify-content: stretch;
  }
  .service-main .header p {
    font-size: 0.9em;
    text-align: end;
    margin-left: 20px;
  }
  .service-main .header h1 {
    text-align: start;
    margin-right: 20px;
  }
}
@media screen and (max-width: 350px) {
  .service-main .our-service .overlay h1 {
    font-size: 3rem;
  }
  .service-main .our-service .overlay p {
    padding: 0px 0rem;
    margin-top: 20px;
  }
  .service-main .our-service {
    height: 75vh;
  }
  .service-main .header {
    gap: 0rem;
  }
}

/* for bigger screen  */

@media screen and (min-width: 1350px) {
  .service-main .services-data {
    max-width: none;
  }
  .service-main .service-last {
    align-items: center;
    justify-content: center;
    /* max-width: 1530px; */
    margin: auto;
    margin-top: 10rem;
  }
  .service-main .header {
    max-width: 1500px;
    margin: 0 auto;
  }
}
