.serviceDropContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 1px;
  text-align: center;
}

.serviceDropContainer h2 {
  text-transform: capitalize;
}

.serviceDropContainer .firstServiceDropCont {
  width: 100%;
  height: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--main-textSecond-color);
  padding: 20px 0;
  padding-top: 100px;
}

.serviceDropContainer .firstServiceDropCont h2 {
  font-weight: 600;
  font-size: 2.5rem;
}

.serviceDropContainer .firstServiceDropCont p {
  width: 50%;
  margin: 15px 0;
  font-weight: 400;
  font-size: 15px;
}

.serviceDropContainer .firstServiceDropCont .buttonBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceDropContainer .firstServiceDropCont .buttonBox button {
  border-style: none;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

@media only screen and (max-width: 1130px) {
  .serviceDropContainer .firstServiceDropCont p {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .serviceDropContainer .firstServiceDropCont {
    min-height: 80vh;
    max-height: fit-content;
    padding-top: 100px;
  }

  .serviceDropContainer .firstServiceDropCont h2 {
    font-size: 1.6rem;
  }
}

.serviceDropContainer .secondServiceDropCont {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--main-text-color);
  padding: 50px 0;
}

.serviceDropContainer .secondServiceDropCont .leftCount {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
}

.serviceDropContainer .secondServiceDropCont .leftCount h2 strong {
  background: var(--main-second-backGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.serviceDropContainer .secondServiceDropCont .leftCount h2 {
  align-self: flex-start;
  margin: 10px 0;
}

.serviceDropContainer .secondServiceDropCont .leftCount p {
  align-self: flex-start;
  width: 80%;
  margin: 10px 0;
}

.serviceDropContainer .secondServiceDropCont .rightCount {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.serviceDropContainer .secondServiceDropCont .rightCount img {
  width: 450px;
}

.serviceDropContainer .secondServiceDropCont .hidden {
  opacity: 0;
  filter: blur(1px);
  visibility: hidden;
  transform: translateY(20%);
  transition: all linear 1s; /* Delay visibility change */
}

.serviceDropContainer .secondServiceDropCont .show {
  opacity: 1;
  filter: blur(0);
  visibility: visible;
  transform: translateY(0);
}

@media only screen and (max-width: 890px) {
  .serviceDropContainer .secondServiceDropCont {
    flex-direction: column;
    padding: 0;
  }
  .serviceDropContainer .secondServiceDropCont .leftCount,
  .serviceDropContainer .secondServiceDropCont .rightCount {
    width: 100%;
    margin: 10px 0;
  }

  .serviceDropContainer .secondServiceDropCont .leftCount h2 {
    width: 100%;
    align-self: center;
  }

  .serviceDropContainer .secondServiceDropCont .leftCount p {
    width: 100%;
    align-self: center;
  }

  .serviceDropContainer .secondServiceDropCont .rightCount img {
    width: 100%;
  }
}

.serviceDropContainer .thirdServiceDropCont,
.serviceDropContainer .fourthServiceDropCont,
.serviceDropContainer .fifthServiceDropCont {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  color: var(--main-text-color);
}

.serviceDropContainer .thirdServiceDropCont h2 strong,
.serviceDropContainer .fourthServiceDropCont h2 strong,
.serviceDropContainer .fifthServiceDropCont h2 strong {
  background: var(--main-second-backGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.serviceDropContainer .thirdServiceDropCont p,
.serviceDropContainer .fourthServiceDropCont p,
.serviceDropContainer .fifthServiceDropCont p {
  width: 80%;
  margin: 10px 0;
}

.serviceDropContainer .thirdServiceDropCont .cardBox {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards {
  width: 45%;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  margin: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  color: var(--main-textSecond-color);
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transform: scale(1);
  transition: transform 1s ease-in-out;
  object-fit: cover;
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards:hover img {
  transform: scale(1.2);
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards .down {
  width: 100%;
  position: absolute;
  z-index: 1;
  color: var(--main-textSecond-color);
  top: 60%;
  bottom: 0;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--main-card-back-color);
  transform: translateY(0);
  transition: transform 0.4s linear;
  padding: 15px;
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards:hover .down {
  transform: translateY(100%);
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards .down h4 {
  width: 100%;
  text-align: start;
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards .text {
  transform: translateY(100%);
  position: absolute;
  top: 0;
  z-index: 1;
  bottom: 0;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: start;
  transition: transform 0.4s linear;
  background-color: var(--main-card-back-color);
}

.serviceDropContainer .thirdServiceDropCont .cardBox .cards:hover .text {
  transform: translateY(0);
}
.serviceDropContainer .thirdServiceDropCont .cardBox .cards h4 {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
}
.serviceDropContainer .thirdServiceDropCont .cardBox .cards p {
  font-size: 17px;
  padding: 10px;
  width: 100%;
}

/* Hidden Animation */
.serviceDropContainer .thirdServiceDropCont .cardBox .hidden,
.serviceDropContainer .fifthServiceDropCont .box .hidden {
  opacity: 0;
  filter: blur(1px);
  visibility: hidden;
  transform: translateY(10%);
  transition: all linear 1s;
}

/* Show Animation */
.serviceDropContainer .thirdServiceDropCont .cardBox .show,
.serviceDropContainer .fifthServiceDropCont .box .show {
  opacity: 1;
  filter: blur(0);
  visibility: visible;
  transform: translateY(0);
}

@media only screen and (max-width: 1306px) {
  .serviceDropContainer .thirdServiceDropCont .cardBox .cards {
    width: 40%;
  }
}

@media only screen and (max-width: 930px) {
  .serviceDropContainer .thirdServiceDropCont .cardBox .cards {
    width: 90%;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 400px) {
  .serviceDropContainer .thirdServiceDropCont .cardBox .cards {
    width: 90%;
    margin: 20px 0;
    height: 380px;
    justify-content: center;
  }

  .serviceDropContainer .thirdServiceDropCont p,
  .serviceDropContainer .fourthServiceDropCont p,
  .serviceDropContainer .fifthServiceDropCont p {
    width: 100%;
    margin: 10px 0;
  }
}

.serviceDropContainer .fourthServiceDropCont .box {
  width: 95%;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 40px 0 20px 0;
}

.serviceDropContainer
  .fourthServiceDropCont
  .box
  .slick-slider
  .slick-prev::before,
.serviceDropContainer
  .fourthServiceDropCont
  .box
  .slick-slider
  .slick-next::before {
  display: none;
}

.serviceDropContainer .fourthServiceDropCont .agencyBox {
  cursor: pointer;
  color: var(--main-text-color);
  margin-bottom: 20px;
}

.serviceDropContainer .fourthServiceDropCont .agencyBox .innerAgencyBox {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  color: var(--main-text-color);
  margin: 0 auto;
  margin-bottom: 10px;
  position: relative;
  perspective: 1000px;
}

.serviceDropContainer .fourthServiceDropCont .agencyBox .innerAgencyBox img {
  width: 150px;
  height: 130px;
  margin: 0 auto;
  border-radius: 20px 0 20px 0;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  transform: rotateY(35deg); /* Adjust the rotation for the 3D effect */
  transition: transform 0.5s ease;
  object-fit: cover;
}

.serviceDropContainer
  .fourthServiceDropCont
  .agencyBox
  .innerAgencyBox:hover
  img {
  transform: rotateY(0deg);
}

.serviceDropContainer .fourthServiceDropCont .agencyBox .innerAgencyBox h5 {
  margin: 10px;
  width: 100%;
  font-size: 1.05rem;
  align-self: center;
  margin: 0 auto;
  padding: 30px 10px;
  font-weight: 600;
  position: absolute;
  top: 60%;
  bottom: 0;
  z-index: 0;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.serviceDropContainer
  .fourthServiceDropCont
  .agencyBox
  .innerAgencyBox:hover
  h5 {
  background: var(--main-second-backGradient-color);
}

.serviceDropContainer .fifthServiceDropCont .box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.serviceDropContainer .fifthServiceDropCont .box .inBox {
  width: 25%;
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
}

.serviceDropContainer .fifthServiceDropCont .box .inBox img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  padding: 2px;
}

.serviceDropContainer .fifthServiceDropCont .box .inBox h6 {
  font-weight: 600;
  font-size: 17px;
  margin-top: 15px;
}

.serviceDropContainer .fifthServiceDropCont .box .inBox:hover img {
  background: var(--main-second-backGradient-color);
}

@media only screen and (max-width: 500px) {
  .serviceDropContainer .fifthServiceDropCont .box .inBox {
    width: 50%;
  }
}

@media only screen and (max-width: 250px) {
  .serviceDropContainer .fifthServiceDropCont .box .inBox {
    width: 100%;
  }
}

@media only screen and (max-width: 814px) {
  .serviceDropContainer .secondServiceDropCont,
  .serviceDropContainer .thirdServiceDropCont,
  .serviceDropContainer .fourthServiceDropCont,
  .serviceDropContainer .fifthServiceDropCont {
    margin: 20px 0;
  }
}
