.careerContainer {
  width: 100%;
}

.careerContainer .sizeBox {
  width: 90%;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 1361px) {
  .careerContainer .sizeBox {
    width: 1360px;
  }
}

.careerContainer .sizeBox .textBox {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.careerContainer .sizeBox .textBox h1,
.careerContainer .sizeBox .textBox p {
  margin: 10px 0;
}

.careerContainer .tabContSlide {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
  overflow-x: auto;
  padding: 10px;
}

.careerContainer .tabContSlide::-webkit-scrollbar {
  display: none;
}

.careerContainer .tabContSlide button {
  padding: 10px 25px;
  border-radius: 5px;
  background-color: rgb(0, 154, 243);
  border-style: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 550;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
  transition: all 0.5s ease;
  text-align: center;
}

.careerContainer .tabContSlide button:hover {
  background-color: #fff;
  color: rgb(26, 25, 25);
}

.careerContainer .tabBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 10px 0;
}

.careerContainer .tabBox .tabBoxChild {
  width: 80%;
  min-height: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 10px;
}

.careerContainer .tabBox .tabBoxChild hr {
  border: 1px solid rgb(230, 230, 230);
  height: 140px;
  align-self: center;
}

.careerContainer .tabBox .tabBoxChild .tChild {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  text-align: center;
  width: 25%;
}

.careerContainer .tabBox .tabBoxChild .tChild span {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.careerContainer .tabBox .tabBoxChild .tChild .applyNow {
  padding: 6px 10px;
  border-radius: 5px;
  background-color: rgb(126, 0, 243);
  border-style: none;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 550;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
  transition: all 0.5s ease;
  text-align: center;
}

.careerContainer .tabBox .tabBoxChild .tChild .skillBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.careerContainer .tabBox .tabBoxChild .tChild .skillBox span {
  padding: 5px 8px;
  background-color: rgb(114, 4, 99);
  color: #fff;
  font-size: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .careerContainer .tabBox .tabBoxChild {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .careerContainer .tabBox .tabBoxChild {
    width: 80%;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .careerContainer .tabBox .tabBoxChild hr {
    width: 90%;
    height: 0px;
  }

  .careerContainer .tabBox .tabBoxChild .tChild{
    width: 90%;
  }
}

