.powerSheetCont .wrapper {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
}

.powerSheetCont .wrapper ul li {
  list-style: none;
}

.powerSheetCont .wrapper header {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;
}

.powerSheetCont .wrapper .current-date {
  font-size: 1.5rem;
  font-weight: 800;
}

.powerSheetCont .wrapper .calendar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
}

.powerSheetCont .wrapper .calendar .weeks {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 10px 0;
}

.powerSheetCont .wrapper .calendar form{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: static;
  flex-direction: column;
}

.powerSheetCont .wrapper .calendar button{
  background-color: #353535;
  color: #fff;
  margin-top: 20px;
  border-style: none;
  padding: 5px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.powerSheetCont .wrapper .calendar button:hover{
  background-color: #202020;
}

.powerSheetCont .wrapper .calendar .days {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: 2px inset #7700ff80;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.powerSheetCont .wrapper .calendar .weeks li {
  text-align: center;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 550;
}

.powerSheetCont .wrapper .calendar .days li {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px;
  box-shadow: rgba(89, 6, 121, 0.37) 0px 1px 3px 0px,
    rgba(65, 2, 136, 0.253) 0px 0px 0px 1px;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
  cursor: pointer;
}

.powerSheetCont .wrapper .calendar .days li input {
  align-self: flex-end;
  width: 30px;
  height: 30px;
  border-style: none;
  text-align: center;
  background-color: #dfdddd1f;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
}

.powerSheetCont .wrapper .calendar .days li input:focus {
  outline: none;
}

.powerSheetCont
  .wrapper
  .calendar
  .days
  li.inactive
  input[readonly]::placeholder {
  color: #dfdddd;
}

.powerSheetCont .wrapper .calendar .days li input[readonly]::placeholder {
  color: #6d6c6c80;
}

.powerSheetCont .wrapper .calendar .days li.weekend {
  background-color: rgba(255, 208, 0, 0.671);
}

.powerSheetCont .wrapper .calendar .days li.national-holiday {
  background-color: #A8C7FA;
}

.powerSheetCont .wrapper .calendar .days li.leave-day {
  background-color: rgba(255, 84, 84, 0.747);
}

.powerSheetCont .wrapper .calendar .days li.active {
  background-color: rgba(57, 5, 117, 0.822);
  color: #fff;
}

.powerSheetCont .wrapper .calendar .days li.readonly-day {
  color: #7c7979;
  background-color: #fff;
}

.powerSheetCont .wrapper .calendar .days li.readonly-day:hover {
  background-color: #e7e7e7ea;
}

.powerSheetCont .wrapper .calendar .days li.inactive {
  color: #eceaea;
}

.powerSheetCont .wrapper .calendar .days li.inactive input {
  color: #dfdddd;
}

.powerSheetCont .wrapper .calendar .days li.inactive:hover {
  background-color: #fff;
}

.powerSheetCont .wrapper .colorCode {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.powerSheetCont .wrapper .colorCode .box {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: #fff;
  margin-top: 20px;
  cursor: pointer;
}

.powerSheetCont .wrapper .colorCode .box:nth-child(1) {
  border-top: 5px solid rgb(10, 157, 177);
}

.powerSheetCont .wrapper .colorCode .box:nth-child(1):hover {
  background-color: rgb(10, 157, 177);
}

.powerSheetCont .wrapper .colorCode .box:nth-child(2) {
  border-top: 5px solid rgba(255, 84, 84, 0.747);
}

.powerSheetCont .wrapper .colorCode .box:nth-child(2):hover {
  background-color: rgba(255, 84, 84, 0.747);
}

.powerSheetCont .wrapper .colorCode .box:nth-child(3) {
  border-top: 5px solid rgba(255, 208, 0, 0.671);
}

.powerSheetCont .wrapper .colorCode .box:nth-child(3):hover {
  background-color: rgba(255, 208, 0, 0.671);
}

.powerSheetCont .wrapper .colorCode .box:nth-child(4) {
  border-top: 5px solid #A8C7FA;
}

.powerSheetCont .wrapper .colorCode .box:nth-child(4):hover {
  background-color: #A8C7FA;
}
